import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import dummmyImg from '../../assets/images/bannerImg.png'
import PropertyCard from '../../components/PropertyCards';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function Properties() {


    const properties = [
        {
            image: dummmyImg,
            price: "$1650/month",
            address: "456A Grand St., Smoky Mountains, TN...",
            bedrooms: "2 bedroom",
            baths: "2 baths",
            size: "256 Sq.Ft."
        },
        {
            image: dummmyImg,
            price: "$1650/month",
            address: "456A Grand St., Smoky Mountains, TN...",
            bedrooms: "2 bedroom",
            baths: "2 baths",
            size: "256 Sq.Ft."
        },
        {
            image: dummmyImg,
            price: "$1650/month",
            address: "456A Grand St., Smoky Mountains, TN...",
            bedrooms: "2 bedroom",
            baths: "2 baths",
            size: "256 Sq.Ft."
        },
        {
            image: dummmyImg,
            price: "$1650/month",
            address: "456A Grand St., Smoky Mountains, TN...",
            bedrooms: "2 bedroom",
            baths: "2 baths",
            size: "256 Sq.Ft."
        },
        {
            image: dummmyImg,
            price: "$1650/month",
            address: "456A Grand St., Smoky Mountains, TN...",
            bedrooms: "2 bedroom",
            baths: "2 baths",
            size: "256 Sq.Ft."
        },

        // You can add more properties here
    ];

    const settings = {
        dots: false,
        infinite: properties.length > 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        slidesToShow: properties.length >= 3 ? 3 : properties.length,
        slidesToScroll: 1,
        centerMode: properties.length === 1,
        centerPadding: properties.length === 1 ? '30%' : '0',
        responsive: [{
            breakpoint: 2560,
            settings: {
                slidesToShow: 3
            }
        }, {
            breakpoint: 990,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 769,
            settings: {
                slidesToShow: 1
            }
        }, {
            breakpoint: 700,
            settings: {
                slidesToShow: 1
            }
        }]
    };



    return (
        <>
            <section className='homeProperties'>
                <div className="container">

                    <div className="row">
                        <div className="col-lg-7">
                            <h2>Properties</h2>
                        </div>
                        <div className="col-lg-5">
                            <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas rerum possimus impedit, qui quisquam magni labore perferendis reprehenderit quidem aperiam ratione maxime inventore magnam et culpa beatae nisi repellat nesciunt.</h6>
                        </div>
                    </div>


                    <div className="row">

                        {/* <div className='tabs-wrapper'> */}
                        <Tabs
                            defaultActiveKey="home"
                            id="fill-tab-example"
                            className="mt-5 mb-3 "
                            fill
                        >
                            <Tab eventKey="home" title="Buy" className='mt-2'>
                                <div className="container">
                                    <Slider {...settings}>
                                        {properties.map((property, index) => (
                                            <PropertyCard key={index} property={property} />
                                        ))}
                                    </Slider>
                                </div>
                            </Tab>
                            <Tab eventKey="profile" title="Sell" className='mt-2'>
                                <div className="container">
                                   <Slider {...settings}>
                                        {properties.map((property, index) => (
                                            <PropertyCard key={index} property={property} />
                                        ))}
                                    </Slider>
                                </div>
                            </Tab>
                            <Tab eventKey="contact" title="Rent" className='mt-2'>
                                <div className="container">
                                    <Slider {...settings}>
                                        {properties.map((property, index) => (
                                            <PropertyCard key={index} property={property} />
                                        ))}
                                    </Slider>
                                </div>
                            </Tab>
                            <Tab eventKey="Development  " title="Development" className='mt-2'>
                                <div className="container">
                                    <Slider {...settings}>
                                        {properties.map((property, index) => (
                                            <PropertyCard key={index} property={property} />
                                        ))}
                                    </Slider>
                                </div>
                            </Tab>



                        </Tabs>
                    </div>
                </div>
            </section>
        </>
    )
}
