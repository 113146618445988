import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import Car from '../../assets/images/testProperties/Car.jpg';
import Hall from '../../assets/images/testProperties/Hall.jpg';
import balco from '../../assets/images/testProperties/balco.png';
import bannerImg from '../../assets/images/testProperties/bannerImg.png';
import bike from '../../assets/images/testProperties/bike.png';
import home from '../../assets/images/testProperties/home.jpg';
import insideRoom from '../../assets/images/testProperties/insideRoom.jpg';
import openLand from '../../assets/images/testProperties/openLand.jpg';
import rightarrow from '../../assets/images/rightarrow.png';
import leftarrow from '../../assets/images/leftarrow.png';
import agent from '../../assets/images/meeting.png';

export default function ViewProperty() {

  const images = [
    { id: 1, image: Car },
    { id: 2, image: balco },
    { id: 3, image: bannerImg },
    { id: 4, image: bike },
    { id: 5, image: home },
    { id: 6, image: insideRoom },
    { id: 7, image: openLand },
  ];

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const NextArrow = ({ onClick }) => {
    return (
      <button className="propertyDetailsNextAndPrevArrow next-arrow" onClick={onClick}>
        <img src={rightarrow} alt="Next" />
      </button>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <button className="propertyDetailsNextAndPrevArrow prev-arrow" onClick={onClick}>
        <img src={leftarrow} alt="Prev" />
      </button>
    );
  };

  const settingsFor = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: nav2,
    ref: (slider) => setNav1(slider),
    adaptiveHeight: true,
  };


  const slidesToShowCount = images.length < 4 ? images.length : 4;
  const settingsNav = {
    slidesToShow: slidesToShowCount,
    slidesToScroll: 1,
    asNavFor: nav1,
    dots: false,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 3000,
    afterChange: (current) => setCurrentSlide(current),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <>
      <section className='propertyDetailsSec p-0'>
        <div className="container">
          <div className="row mt-5">
            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="row">
                <div className="col-12">
                  <div className="slider-container position-relative">
                    <img src={images[currentSlide].image} className='mainImg' alt={`Main Image ${currentSlide + 1}`} />
                    {nav1 && <PrevArrow onClick={() => nav1.slickPrev()} />}
                    {nav1 && <NextArrow onClick={() => nav1.slickNext()} />}
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <Slider {...settingsNav} className="slider-nav">
                    {images.map((img, index) => (
                      <img key={img.id} src={img.image} className='thumbnailImgs px-1' alt={`Thumbnail ${index + 1}`} />
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="row">
                <div className="col-12">
                  <div className="card py-4">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <h4>RichardSon, Fort Villa</h4>
                          <h6 className="mt-3"> 21 Ajith Singh nagar, Vishakapatnam, Andhra Pradesh, 520015</h6>
                        </div>
                        <div className="col-xl-3 col-lg-5 col-md-6 col-sm-3 col-4 mt-3">
                          <p><i class="fa-solid fa-bath"></i> 3 Bath</p>
                        </div>
                        <div className="col-xl-9 col-lg-7 col-md-6 col-sm-6 col-4 mt-3">
                          <p> <i class="fa-solid fa-bed"></i> 3 Bed</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-3 priceDiv">
                          <h6>Price</h6>
                          <h5 className="pt-1">$100,000</h5>
                           
                        </div>
                      </div>

                      <div className="row mt-3">
                        <h5>Contact with an agent</h5>
                        {/* <div className="col-lg-1 col-md-3 col-sm-2 col-2">
                          <img src={agent} alt="Agent" />
                        </div> */}
                        <div className="col-12 mt-2">
                          <h6>Posted By: Owner</h6>
                          <h6 className="mt-2 getCallTag"><i class="fa-solid fa-phone"></i> Click to Get Details</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              cmcmx,,
            </div>
          </div>
        </div>
      </section>
    </>
  );
}






