import React from 'react'
import ourServiccesImg from '../../assets/images/ourServiccesImg.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function OurServices() {


    const settings = {
        dots: false,
        arrows: false,
        infinite: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,  
        centerPadding: "0",
        responsive: [{
            breakpoint: 2560,
            settings: {
                slidesToShow: 3
            }
        }, {
            breakpoint: 1199,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 992,
            settings: {
                slidesToShow: 1
            }
        }, {
            breakpoint: 700,
            settings: {
                slidesToShow: 1
            }
        }]
    };

    return (
        <>
            <section className='ourServicesSec'>
                <div className="container">
                    <div className="card mainCard py-5 px-1 px-sm-2 px-md-5 px-lg-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <p>Our Services</p>
                                    <h2>What we offer</h2>
                                </div>
                                <div className="col-lg-5">
                                    <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas rerum possimus impedit, qui quisquam magni labore perferendis reprehenderit quidem aperiam ratione maxime inventore magnam et culpa beatae nisi repellat nesciunt.</h6>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <Slider {...settings}>
                                        <div className="card subCards py-5">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="co-12">
                                                        <img src={ourServiccesImg} alt="" />
                                                    </div>
                                                    <div className="col-12 mt-5">
                                                        <h5>Lorem ipsum dolor sit amet. </h5>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quisquam repellat vitae, dolorum labore saepe reprehenderit perspiciatis ab inventore voluptate quo obcaecati distinctio quaerat impedit eius? Accusantium quo ex deserunt!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card subCards py-5">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="co-12">
                                                        <img src={ourServiccesImg} alt="" />
                                                    </div>
                                                    <div className="col-12 mt-5">
                                                        <h5>Lorem ipsum dolor sit amet. </h5>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quisquam repellat vitae, dolorum labore saepe reprehenderit perspiciatis ab inventore voluptate quo obcaecati distinctio quaerat impedit eius? Accusantium quo ex deserunt!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card subCards py-5">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="co-12">
                                                        <img src={ourServiccesImg} alt="" />
                                                    </div>
                                                    <div className="col-12 mt-5">
                                                        <h5>Lorem ipsum dolor sit amet. </h5>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quisquam repellat vitae, dolorum labore saepe reprehenderit perspiciatis ab inventore voluptate quo obcaecati distinctio quaerat impedit eius? Accusantium quo ex deserunt!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card subCards py-5">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="co-12">
                                                        <img src={ourServiccesImg} alt="" />
                                                    </div>
                                                    <div className="col-12 mt-5">
                                                        <h5>Lorem ipsum dolor sit amet. </h5>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quisquam repellat vitae, dolorum labore saepe reprehenderit perspiciatis ab inventore voluptate quo obcaecati distinctio quaerat impedit eius? Accusantium quo ex deserunt!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
