import React, { useState } from 'react'
import MainLogo from '../../assets/images/MainLogo.png'
import userLogin from '../../assets/images/userLogin.png'
import search from '../../assets/images/search.png'
// import PostProp from '../../assets/images/PostProp.png'

import Menu from './Menu';
import Login from './LoginPage';
import { Link, useLocation } from 'react-router-dom';
export default function NavBar() {
    const [openMenu, setOpenMenu] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);

    const location = useLocation();
    const isActive = (path) => location.pathname === path ? 'nav-link active' : 'nav-link';

    return (
        <>
            <section className="header_section p-0" id='header_section'>
                <nav className="navbar navbar-expand-lg nav_bar">
                    <div className="container">
                        <Link to="/" className="navbar-brand"> <img src={MainLogo} width="100px" alt="" /></Link>
                        <div className="collapse navbar-collapse text-center" id="collapsibleNavbar">
                            <ul className="navbar-nav ms-auto me-2 mainHeaderLinks header_links">
                                <li className="nav-item">
                                    <Link to="/" className={isActive('/')}><i className="fa-solid fa-house"></i></Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/my-fav-properties" className={isActive('/my-fav-properties')}><i class="fa-solid fa-heart"></i></Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/find-your-property" className={isActive('/find-your-property')}><span><img src={search} alt="" /> Explore</span></Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to="/post-your-property" className={isActive('/post-your-property')}><i class="fa-solid fa-building"></i></Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link to="/post-your-property" className={isActive('/post-your-property')}>Post Your Property</Link>
                                </li>

                               
                                {/* <li className="nav-item">
                                    <Link to="/my-bookings" className={isActive('/my-bookings')}><i class="fa-solid fa-book"></i></Link>
                                </li> */}
                            </ul>
                            <ul className="navbar-nav ms-auto me-3 header_links">
                                {/* <li className="nav-item" onClick={() => setOpenLogin(true)}>
                                    <img src={userLogin} width="80%" alt="" />
                                </li> */}
                                <li className="nav-item" >
                                <Link to="/sign-in" className={isActive('/sign-in')}><img src={userLogin} width="80%" alt="" /></Link>
                                </li> 
                            </ul>
                        </div>
                        <ul className="navbar-nav ms-aut">
                            <li className="nav-item">
                                <span className="MenuSpan" onClick={() => setOpenMenu(true)}>
                                    <i className="fa-solid fa-bars"></i>{" "}
                                    <span className="ms-1" >Menu</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </nav>
                <Menu openMenu={openMenu} setOpenMenu={setOpenMenu} />
                <Login openLogin={openLogin} setOpenLogin={setOpenLogin} />
            </section>
        </>
    )
}
