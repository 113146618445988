import React from 'react'
import MainLogo from '../assets/images/MainLogo.png'
import NavBar from './Header/NavBar'
export default function Header() {
  return (
    <>
      <NavBar />
    </>
  )
}
