import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import facebook from "../../assets/images/facebook.png";
import youtube from "../../assets/images/youtube.png";
import instagram from "../../assets/images/instagram.png";
import linkedin from "../../assets/images/linkedin.png";

export default function Menu({ openMenu, setOpenMenu }) {
    // Use state to control the Offcanvas visibility
    const handleClose = () => setOpenMenu(false);
    const handleShow = () => setOpenMenu(true);

    const location = useLocation();
    const isActive = (path) =>
        location.pathname === path ? "Links active" : "Links";

    const isPropertiesActive = ["/find-your-property", "/post-your-property"].includes(location.pathname);
    const isProfileActive = ["/my-properties", "/my-fav-properties", "/my-bookings"].includes(location.pathname);


    return (
        <>
            <section className="p-0 menuSec">
                <Offcanvas
                    show={openMenu}
                    onHide={handleClose}
                    className="menuOffCanvas"
                    placement="end"
                >
                    <Offcanvas.Header>
                        <div className="container">
                            <div className="row pt-6 px-6 ">
                                <div className="col-10 ">
                                    <h4>MENU</h4>
                                </div>
                                <div className="col-2 text-end">
                                    <button
                                        className="clsBtn"
                                        onClick={() => setOpenMenu(false)}
                                    ></button>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Header>

                    <hr className="mx-4 my-1" />

                    <Offcanvas.Body>
                        <div className="container offcanvasContainer py-3 px-4">
                            <div className="row">
                                <div className="col-12">
                                    <Link className={isActive("/")} to="/" onClick={handleClose}>
                                        HOME
                                    </Link>
                                </div>

                                <div className="col-12">
                                    <Link
                                        className={isActive("/about-us")}
                                        to="/about-us"
                                        onClick={handleClose}
                                    >
                                        ABOUT US
                                    </Link>
                                </div>
                                <Accordion defaultActiveKey={isPropertiesActive ? "0" : null} className="p-0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <span className={`accordianHeader ${isPropertiesActive ? "active" : ""}`}>
                                                PROPERTIES
                                            </span>
                                        </Accordion.Header>
                                        <Accordion.Body className="pt-0">
                                            <div>
                                                <Link
                                                    className={isActive("/find-your-property")}
                                                    onClick={handleClose}
                                                    to="/find-your-property"
                                                >
                                                    ALL PROPERTIES
                                                </Link>
                                            </div>
                                            <div>
                                                <Link
                                                    className={isActive("/post-your-property")}
                                                    onClick={handleClose}
                                                    to="/post-your-property"
                                                >
                                                    POST YOUR PROPERTY
                                                </Link>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                                <Accordion defaultActiveKey={isProfileActive ? "1" : null} className="p-0">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            <span className={`accordianHeader ${isProfileActive ? "active" : ""}`}>
                                                PROFILE
                                            </span>
                                        </Accordion.Header>
                                        <Accordion.Body className="pt-0">
                                            <div>
                                                <Link
                                                    className={isActive("/my-properties")}
                                                    onClick={handleClose}
                                                    to="/my-properties"
                                                >
                                                    MY PROPERTIES
                                                </Link>
                                            </div>
                                            <div>
                                                <Link
                                                    className={isActive("/my-fav-properties")}
                                                    onClick={handleClose}
                                                    to="/my-fav-properties"
                                                >
                                                    FAVOURITES
                                                </Link>
                                            </div>
                                            <div>
                                                <Link
                                                    className={isActive("/my-bookings")}
                                                    onClick={handleClose}
                                                    to="/my-bookings"
                                                >
                                                    PROFILE
                                                </Link>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <div className="col-12">
                                    <Link className={isActive("/")} to="/" onClick={handleClose}>
                                        HOME LOAN EMI CALCULATOR
                                    </Link>
                                </div>

                                <div className="col-12">
                                    <Link className={isActive("/contact-us")} to="/contact-us" onClick={handleClose}>
                                        CONTACT US
                                    </Link>
                                </div>
                                <div className="col-12">
                                    <Link className={isActive("/")} to="/" onClick={handleClose}>
                                        LOGOUT
                                    </Link>
                                </div>
                                <div className="col-12">
                                    <div className="row pt-3">
                                        <div className="col-3">
                                            <Link>
                                                <img src={facebook} alt="" />
                                            </Link>
                                        </div>
                                        <div className="col-3">
                                            <Link>
                                                <img src={youtube} alt="" />
                                            </Link>
                                        </div>
                                        <div className="col-3">
                                            <Link>
                                                <img src={instagram} alt="" />
                                            </Link>
                                        </div>
                                        <div className="col-3">
                                            <Link>
                                                <img src={linkedin} alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </section>
        </>
    );
}
