import React from 'react'
import Slider from "react-slick";
import aboutImg from '../../assets/images/bannerImg.png'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Blogs() {

    const settings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,

        responsive: [{
            breakpoint: 2560,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 1050,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1
            }
        }, {
            breakpoint: 700,
            settings: {
                slidesToShow: 1
            }
        }
        ]
    };

    const blogs = [
        {
            id: 1,
            title: "Revolutionizing Real Estate: The Impact of PropTech on India's Property Market  ",
            slug: '',
            long_desc: '',
            published_date: '',
            file_upload: aboutImg,

        },{
            id: 1,
            title: "The Green Revolution: Sustainability Trends in Indian Real Estate ",
            slug: '',
            long_desc: '',
            published_date: '',
            file_upload: aboutImg,
        },{
            id: 1,
            title: "The Green Revolution: Sustainability Trends in Indian Real Estate ",
            slug: '',
            long_desc: '',
            published_date: '',
            file_upload: aboutImg,
        },
    ]

    return (
        <>
            <section class="BlogSec  " id=" ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-center">
                            <div>
                                <h2 >Blogs</h2>
                                <p >Explore our blog for a wealth of knowledge, insights, and inspiration. Our expertly crafted articles cover a wide range of topics, from industry trends to practical tips, guides, latest industry trends, best practices, and in-depth tech discussions. Stay informed, get inspired, and delve deeper into the subjects that matter to you with our blog content.</p>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-6 col-sm-12 col-12">
                            <Slider {...settings}>
                                {blogs.map((blog, index) => (
                                    <div class="card img-fluid" >
                                    <img class="card-img-top" src={blog.file_upload}
                                        alt="Card image" width="100%" height="350px" />
                                    <div class="card-img-overlay d-flex align-items-end">
                                        <div>
                                            <h4 class="">{blog.title}</h4>
                                            <button class="blogSlugBtn mt-3"><i class="fa-solid fa-arrow-right"></i></button>
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </Slider>
                        </div>
                    </div>

                </div >
            </section >
        </>
    )
}
