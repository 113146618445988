import React from 'react'
import { Link } from 'react-router-dom'
import MainLogo from '../../assets/images/MainLogo.png'
import login1 from '../../assets/images/login/login.jpeg'
import login2 from '../../assets/images/login/login2.jpeg'
import login3 from '../../assets/images/login/login3.jpeg'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




export default function UserSignIn() {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };



    return (
        <>
            <section class="signinSec p-0">
                <div class="container">
                    <div className="row">
                        <div className="col-12">
                            <div class="card MainCard">
                                <div className="container">
                                    <div className="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-none d-sm-block order-lg-1 order-md-1 order-sm-2 order-1">
                                            <div className='sliderCard'>
                                                <Slider {...settings}>
                                                    <div class="card">
                                                        <img src={login1} alt="Sample Image" class="card-img" />
                                                        <div class="card-img-overlay toptext">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <img src={MainLogo} width="100px" alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-img-overlay bottomText">
                                                            <div className="row">
                                                                <div className="col-12 text-center">
                                                                    <p>Hello Lorem ipsum dolor, adipisicing el</p>
                                                                    <p>Hello  el</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card">
                                                        <img src={login2} alt="Sample Image" class="card-img" />
                                                        <div class="card-img-overlay toptext">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <img src={MainLogo} width="100px" alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-img-overlay bottomText">
                                                            <div className="row">
                                                                <div className="col-12 text-center">
                                                                    <p>Hello Lorem ipsum dolor, adipisicing el</p>
                                                                    <p>Hello  el</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card">
                                                        <img src={login3} alt="Sample Image" class="card-img" />
                                                        <div class="card-img-overlay toptext">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <img src={MainLogo} width="100px" alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-img-overlay bottomText">
                                                            <div className="row">
                                                                <div className="col-12 text-center">
                                                                    <p>Hello Lorem ipsum dolor, adipisicing el</p>
                                                                    <p>Hello  el</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Slider>
                                            </div>

                                        </div>

                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 order-lg-2 order-md-2 order-sm-1 order-2 d-flex align-items-center">
                                            <div className="row">
                                                <div className='col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-10 offset-md-1'>
                                                    <h2>Log In to Your TheRealtors Account!</h2>
                                                    <div className='mt-4 d-none'>
                                                        <Link to="/" className='backWebBtn mt-4'>Back To Website</Link>
                                                    </div>
                                                    <div className="row mt-5">
                                                        <div className="col-12">
                                                            <div class="custom-floating-input">
                                                                <input type="text" id="floatingInput" placeholder=" " class="form-control" autoComplete='off' />
                                                                <label for="floatingInput">Email</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-3">
                                                            <div class="custom-floating-input">
                                                                <input type="text" id="floatingInput" placeholder=" " class="form-control" autoComplete='off' />
                                                                <label for="floatingInput">Password</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 mt-3 text-end">
                                                            <Link>Forgot Password? </Link>
                                                        </div>
                                                        <div className="col-12 mt-3">
                                                            <button className="allBtns w-100">Log In</button>
                                                        </div>
                                                        <div className="col-12 mt-3">
                                                            Don't have an account ? <Link to="/sign-up">Sign Up</Link>
                                                        </div>
                                                        <div className="col-12 mt-3">
                                                        <Link to="/"> Back to Website</Link>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
