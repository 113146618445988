import React from 'react'
import { Link } from 'react-router-dom'

export default function ErrorPage() {
  return (
    <>
     <section className='errorPage d-flex justify-content-center align-items-center'>
        <div className="container">
            <div className="row text-center">
                <div className="col-12">
                    <h1>404</h1>
                    <p>Page Not Found <Link to="/">Return Home</Link></p>
                </div>
            </div>
        </div>
    </section> 
    </>
  )
}
