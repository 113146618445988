import React, { useState } from 'react';
import mapImg from '../assets/images/mapImg.png'
import Aboutus from '../components/Home/Aboutus'
import Properties from '../components/Home/Properties'
import OurServices from '../components/Home/OurServices'
import Select from 'react-select';
import Slider from "react-slick";
import dummmyImg from '../assets/images/bannerImg.png'
import PropertyCard from '../components/PropertyCards';
import Blogs from '../components/Home/Blogs';

export default function HomePage() {

  const jobTypeOptions = [
    { value: ' ', label: 'Select Job Type' },
    { value: 'UI / UX Developer', label: 'UI / UX Developer' },
    { value: 'UI Designer', label: 'UI Designer' },
    { value: 'Web Developer', label: 'Web Developer' },
    { value: 'Mobile Developer', label: 'Mobile Developer' },
  ];

  const locationOptions = [
    { value: ' ', label: 'Location' },
    { value: 'Hyderabad', label: 'Hyderabad' },
    { value: 'Vijayawada', label: 'Vijayawada' },
    { value: 'Vishakapatanam', label: 'Vishakapatanam' },
    { value: 'Guntur', label: 'Guntur' },
  ];





  const [selectedJobType, setSelectedJobType] = useState('');
  const handleJobType = (selectedOption) => {
    setSelectedJobType(selectedOption.value);
  };

  const [selectedLocation, setSelectedLocation] = useState('');
  const handleLocation = (selectedOption) => {
    setSelectedLocation(selectedOption.value);
  }



  var settings = {
    dots: false,
    arrow: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [{
      breakpoint: 990,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 769,
      settings: {
        slidesToShow: 1
      }
    }, {
      breakpoint: 700,
      settings: {
        slidesToShow: 1
      }
    }]
  };




  const properties = [
    {
      image: dummmyImg,
      price: "$1650/month",
      address: "456A Grand St., Smoky Mountains, TN...",
      bedrooms: "2 bedroom",
      baths: "2 baths",
      size: "256 Sq.Ft."
    },
    {
      image: dummmyImg,
      price: "$1650/month",
      address: "456A Grand St., Smoky Mountains, TN...",
      bedrooms: "2 bedroom",
      baths: "2 baths",
      size: "256 Sq.Ft."
    },
    {
      image: dummmyImg,
      price: "$1650/month",
      address: "456A Grand St., Smoky Mountains, TN...",
      bedrooms: "2 bedroom",
      baths: "2 baths",
      size: "256 Sq.Ft."
    },
    {
      image: dummmyImg,
      price: "$1650/month",
      address: "456A Grand St., Smoky Mountains, TN...",
      bedrooms: "2 bedroom",
      baths: "2 baths",
      size: "256 Sq.Ft."
    },
    {
      image: dummmyImg,
      price: "$1650/month",
      address: "456A Grand St., Smoky Mountains, TN...",
      bedrooms: "2 bedroom",
      baths: "2 baths",
      size: "256 Sq.Ft."
    },

    // You can add more properties here
  ];



  return (
    <>
      <section className='homeFirstSec p-0'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 d-flex align-items-center">
              <div className="row ">
                <div className="col-xl-9 offset-xl-1 col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12 offset-sm-0 col-12 ">
                  <div className="row">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 pt-5">
                      <h4>Find your next home</h4>
                      <h1>Buying, Renting, Selling</h1>
                      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates praesentium delectus nulla repellendus, eaque
                        vero quis ducimus at provident, corporis commodi quidem? A aperiam earum reiciendis, eveniet dignissimos dolore sed.
                      </p>
                    </div>
                    <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12 pb-5">
                    <div className="row mt-5">
                        <div className="col-12">
                          <div class="radio-buttons-container">
                            <div class="radio-group">
                              <input type="radio" id="time1" name="time" value="Buy" />
                              <label for="time1">Buy</label>

                              <input type="radio" id="time2" name="time" value="Sell" />
                              <label for="time2">Sell</label>

                              <input type="radio" id="time3" name="time" value="Development" />
                              <label for="time3">Development</label>

                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="card border-0 HomeSmCard">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 SelectCol mt-3 mt-xs-3 mt-sm-3 mt-md-0 mt-lg-0">
                                  <Select className='allSelects' options={jobTypeOptions} onChange={handleJobType} value={jobTypeOptions.find(option => option.value === selectedJobType)}
                                  />
                                  <p id="jobTypeTag" className='px-2'>{selectedJobType}</p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-3 mt-xs-3 mt-sm-3 mt-md-0 mt-lg-0" >
                                  <Select className='allSelects' options={locationOptions} onChange={handleLocation} value={locationOptions.find(option => option.value === selectedLocation)}
                                  />
                                  <p id="locationTag" className='px-2'>{selectedLocation}</p>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-3 mt-xs-3 mt-sm-3 mt-md-0 mt-lg-0 ">
                                  <button className='allBtns w-100' >EXPLORE</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className='d-none d-lg-block'>
                <img src={mapImg} width="100%" height="650px" alt="" />
              </div>

              <div className="displayPrperty d-block d-lg-none">
                <Slider {...settings}>
                  {properties.map((property, index) => (
                    <div className="">
                      <PropertyCard key={index} property={property} />
                    </div>
                  ))}
                </Slider>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className='p-0 displayCardSection'>
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12  ">
            </div>
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
              <div className="displayPrperty">
                <Slider {...settings}>
                  {properties.map((property, index) => (
                    <div className="">
                      <PropertyCard key={index} property={property} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Aboutus />
      <Properties />
      <OurServices />
      <Blogs />
    </>
  )
}
