import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export default function PostYourProperty() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2>Sell or Rent your Property For Free</h2>
                            <p>List your property with us for maximum exposure. Our targeted marketing strategies and professional support ensure your property stands out. With detailed listings and secure transactions, connect with qualified buyers and tenants effortlessly. Start today for a hassle-free experience!</p>
                        </div>
                        <div className="card mt-5 py-5 border-0">
                            <div className="container">
                                <div className="row d-none">
                                    <div className="col-lg-2">
                                        <select name="" id="">
                                            <option value="">Select Your Location</option>
                                            <option value="">Vijayawada</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-2">
                                        <select name="" id="">
                                            <option value="">Select Your Location</option>
                                            <option value="">Vijayawada</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-3">
                                        <select name="" id="">
                                            <option value="">Select Your Location</option>
                                            <option value="">Vijayawada</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-2">
                                        <select name="" id="">
                                            <option value="">Select Your Location</option>
                                            <option value="">Vijayawada</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-3">
                                        <button className='allBtns w-100'>NEXT</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <Tabs
                                            defaultActiveKey="home"
                                            id="fill-tab-example"
                                            className=""
                                            fill
                                        >
                                            <Tab eventKey="home" title="Buy" className='mt-2'>
                                                <div className="container">
                                                     hello htis is test
                                                </div>
                                            </Tab>
                                            <Tab eventKey="profile" title="Sell" className='mt-2'>
                                                <div className="container">
                                                     hello htis is test
                                                </div>
                                            </Tab>
                                            <Tab eventKey="contact" title="Rent" className='mt-2'>
                                                <div className="container">
                                                     hello htis is test
                                                </div>
                                            </Tab>
                                            <Tab eventKey="Development  " title="Development" className='mt-2'>
                                                <div className="container">
                                                     hello htis is test
                                                </div>
                                            </Tab>



                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
