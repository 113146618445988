import React from "react";
import MainLogo from "../assets/images/MainLogo.png";

import facebook from "../assets/images/facebook.png";
import youtube from "../assets/images/youtube.png";
import instagram from "../assets/images/instagram.png";
import linkedin from "../assets/images/linkedin.png";

import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <section className="footer_section">
        <div className="container">
          <div className="row main_row">
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-5">
              <img src={MainLogo} width="50%" alt="" />
              <p className="mt-3">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam ipsum quibusdam nam doloremque itaque. Distinctio vitae molestias fuga sunt excepturi laboriosam vero et, sapiente totam laudantium obcaecati dolorum quidem minus?</p>

            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-6 mt-5">
              <h5>COMPANY</h5>
              <Link><p>Home</p></Link>
              <Link><p>About Us</p></Link>
              <Link><p>Careers</p></Link>
              <Link><p>Contact Us</p></Link>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 mt-5">
              <h5>PROPERTIES</h5>
              <Link><p>Find A Property</p></Link>
              <Link><p>Post Your Property</p></Link>

            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12 pt-5">
              <div className="">
                <h5>OTHER OFFERINGS</h5>
                <Link><p>Home Loan Calculator</p></Link>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-8 col-md-12 col-sm-12 order-lg-1 order-md-2 order-sm-2 text-lg-start text-md-center text-sm-center ">
              <Link target="_blank" to="https://tarved.com/"><p className="pt-3">Developed by Tarved Solution</p></Link>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 order-lg-2 order-md-1 order-sm-1">
              <div className="row pt-3">
                <div className="col-4 d-flex align-items-center justify-content-center">
                  <h6>Connect</h6>
                </div>
                <div className="col-2">
                  <Link><img src={facebook} alt="" /></Link>
                </div>
                <div className="col-2">
                  <Link><img src={youtube} alt="" /></Link>

                </div>
                <div className="col-2">
                  <Link><img src={instagram} alt="" /></Link>

                </div>
                <div className="col-2">
                  <Link><img src={linkedin} alt="" /></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row last_row pt-3">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <p className="allright_text">Copyright ©2024 Real All rights reserved</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ">
              <Link className="footerSameLink"><p>Terms Conditions</p></Link>
              <Link className="footerSameLink privacy_link"><p>Privacy Policy</p></Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
