import React from 'react'
import mapImg from '../assets/images/mapImg.png'
import Accordion from "react-bootstrap/Accordion";
export default function Contactus() {
    return (
        <>
            <section className='contactusSec'>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12 mt-3">
                            <div className="row">
                                <div className="col-xl-6 col-lg-12">
                                    <h2>Contact Us</h2>
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. At, molestias accusantium dolore natus vero sequi cumque inventore optio repellendus perferendis nesciunt quas, esse praesentium assumenda ea animi facilis nam? Pariatur.</p>
                                    <p className='mt-3'><i class="fa-solid fa-envelope"></i> hello@example.com</p>
                                    <p className='mt-3'><i class="fa-solid fa-phone"></i> hello@example.com</p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-lg-4">
                                    <h6>Custom Support</h6>
                                    <p> iure, quo, necessitatibus ipsa corrupti molestias ut! Officiis voluptas officia earum vero expedita quibusdam!</p>
                                </div>
                                <div className="col-lg-4">
                                    <h6>Custom Support</h6>
                                    <p> iure, quo, necessitatibus ipsa corrupti molestias ut! Officiis voluptas officia earum vero expedita quibusdam!</p>
                                </div>
                                <div className="col-lg-4">
                                    <h6>Custom Support</h6>
                                    <p> iure, quo, necessitatibus ipsa corrupti molestias ut! Officiis voluptas officia earum vero expedita quibusdam!</p>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12 mt-3">
                            <div className="card border-0">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-10 offset-sm-1 py-lg-5 py-md-5 py-sm-5 py-3">
                                            <h4>Get In Touch</h4>
                                            <p className='mb-4'>You can get in touch with any time</p>

                                            <form name='contactForm' id='contactForm'>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 mt-3">
                                                        <div class="custom-floating-input">
                                                            <input type="text" id="floatingInput" placeholder=" " class="form-control" autoComplete='off' />
                                                            <label for="floatingInput">First name</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 mt-3">
                                                        <div class="custom-floating-input">
                                                            <input type="text" id="floatingInput" placeholder=" " class="form-control" autoComplete='off' />
                                                            <label for="floatingInput">Last name</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-6 mt-3">
                                                        <div class="custom-floating-input">
                                                            <input type="text" id="floatingInput" placeholder=" " class="form-control" autoComplete='off' />
                                                            <label for="floatingInput">Email</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-6 mt-3">
                                                        <div class="custom-floating-input">
                                                            <input type="text" id="floatingInput" placeholder=" " class="form-control" autoComplete='off' />
                                                            <label for="floatingInput">Phone</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 mt-3">
                                                        <textarea rows="5" name="message" placeholder="Message" autoComplete="off"
                                                            className="message" id="message"></textarea>
                                                    </div>
                                                    <div className="col-12">
                                                        <button className="allBtns w-100 mt-3">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className=''>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center order-lg-1 order-md-2 order-sm-2 order-2 mt-3">
                            <img src={mapImg} width="100%" alt="" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center order-lg-2 order-md-1 order-sm-1 order-1 mt-3">
                            <div>
                                <p>Our Location</p>
                                <h2>Connecting Near and Far</h2>
                                <p>Sri SPR Global School, <br /> Chittaramma Devi Nagar,
                                    Gajularamaram, Hyderabad, <br /> Telandana - 500055
                                    <br />  Phone: (+91) 799 799 5252</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className='contactFaqsSec'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <p>FAQ's</p>
                            <h2>Do You Have Any Questions For Us ?</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt nobis eligendi quam odit, perspiciatis sequi fugit sit? Fugiat molestiae, expedita quaerat quod aspernatur distinctio commodi delectus sint ad quis repellendus?</p>
                        </div>
                        <div className="col-lg-7">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>FAQ 1</Accordion.Header>
                                    <Accordion.Body className="py-4">
                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe, expedita dolor earum mollitia quisquam, reiciendis quaerat obcaecati quidem libero recusandae perspiciatis dolorum tempore eligendi ipsa quas tenetur accusantium. Soluta, debitis.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>FAQ 2</Accordion.Header>
                                    <Accordion.Body className="py-4">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt quae et totam quo numquam fugit assumenda necessitatibus nobis a deserunt architecto reprehenderit vitae qui illo vero, reiciendis, delectus corporis quam?.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>FAQ 3</Accordion.Header>
                                    <Accordion.Body className="py-4">
                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nostrum beatae consectetur tempora, commodi impedit, necessitatibus quaerat distinctio, hic veniam est animi laborum rerum asperiores odio fugit nobis esse itaque earum.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" >
                                    <Accordion.Header>FAQ 4</Accordion.Header>
                                    <Accordion.Body className="py-4">
                                         <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere corporis temporibus consequuntur quod quam harum tenetur, sapiente omnis. Natus labore nulla voluptas vitae asperiores ducimus ipsam perspiciatis fuga molestiae officiis?</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}
