import React from 'react'
import aboutImg from '../../assets/images/bannerImg.png'
import { Link } from 'react-router-dom'
export default function Aboutus() {
    return (
        <>
            <section className='aboutusSec'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center order-lg-1 order-md-2 order-sm-2 order-2 mt-3">
                            <img src={aboutImg} width="80%" alt="" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center order-lg-2 order-md-1 order-sm-1 order-1 mt-3">
                            <div>
                                <p>About Us</p>
                                <h2>Modern way of learning and development</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum quod ut quis facilis? Quod minus qui, autem facere consequatur tenetur suscipit consequuntur non maxime, inventore ex aut dolorum illo quas?</p>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Exercitationem necessitatibus quia inventore ipsa recusandae voluptate quae asperiores ipsam voluptatum rem, vero vel, et blanditiis beatae deserunt minima quod id sint!</p>
                                <Link to="/about-us"><button className='allBtns mt-4'>Learn More</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
