import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import Header from '../components/Header';
import HomePage from '../pages/HomePage';
import Footer from '../components/Footer';
import Aboutus from './Aboutus';
import ErrorPage from './ErrorPage';
import FindProperty from './Property/FindProperty';
import PostYourProperty from './Property/PostYourProperty';
import ViewProperty from './Property/ViewProperty';
import Contactus from './Contactus';
import UserSignIn from '../components/UserLogin/UserSignIn';
import UserSignUp from '../components/UserLogin/UserSignUp';


  


export default function RootRouter() {
  const [isSignedIn, setIsSignedIn] = useState(false);

  return (
    <BrowserRouter>
      <AppContent isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />
    </BrowserRouter>
  );
}

function AppContent({ isSignedIn, setIsSignedIn }) {
  const location = useLocation();

  // Hide header and footer if signed in or on sign-in or sign-up page
  const hideHeaderFooter = isSignedIn || location.pathname === '/sign-in' || location.pathname === '/sign-up';

  return (
    <div>
      {!hideHeaderFooter && <Header />}
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about-us' element={<Aboutus />} />
        <Route path='/find-your-property' element={<FindProperty />} />
        <Route path="/post-your-property" element={<PostYourProperty />} />
        <Route path="/view-property" element={<ViewProperty />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route 
          path='/sign-in' 
          element={<UserSignIn onSignIn={() => setIsSignedIn(true)} />} 
        />
        <Route 
          path='/sign-up' 
          element={<UserSignUp onSignUp={() => setIsSignedIn(true)} />} 
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      {!hideHeaderFooter && <Footer />}
    </div>
  );
}