import React from 'react'

export default function PropertyCards({ property }) {
    return (
        <>
            <div className="property-card mt-3">
                <img src={property.image} alt="House" className="property-image mb-3" />
                <div className="badge-container">
                    <span className="badge bg-primary">Suitable for children</span>
                    <span className="badge bg-info text-dark">Pets friendly</span>
                </div>
                <div className='property-info'>
                    <div className="price">
                        <h6>{property.price}</h6>
                    </div>
                    <div className="info-section">
                        <div>{property.address}</div>
                    </div>
                    <div className="text-end">
                        <a href="#" className="text-primary">View home</a>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mt-2 p-0">
                                <div className="d-flex align-items-center">
                                    <i className="fa-solid fa-house me-2"></i>
                                    <p>{property.bedrooms}</p>
                                </div>
                            </div>
                           

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mt-2 p-0">
                                <div className="d-flex align-items-center">
                                    <i className="fa-solid fa-house me-2"></i>
                                    <p>{property.baths}</p>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mt-2 p-0">
                                <div className="d-flex align-items-center">
                                    <i className="fa-solid fa-house me-2"></i>
                                    <p>{property.size}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
