 






import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import LoginImg from "../../assets/images/Login.jpg";

export default function SignUpPage({ openSignUp, setOpenSignUp, setOpenLogin }) {
    const handleClose = () => setOpenSignUp(false);

    return (
        <>
            <section className='p-0'>
                <Modal
                    show={openSignUp}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    onHide={handleClose}
                    centered
                    className="LoginModel"
                >
                    <Modal.Header closeButton className="ModelHeader">
                        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 d-flex align-items-center justify-content-center">
                                    <img src={LoginImg} alt="Login" width="100%" />
                                </div>
                                <div className="col-lg-7">
                                    <div className="row">
                                        <h2 className="text-center">Signup</h2>
                                        <div className="col-lg-6 col-md-12 col-sm-12 ">
                                            <div className="input-group mt-3">
                                                <label htmlFor="">First Name</label>
                                                <input
                                                    type="text"
                                                    placeholder="First Name"
                                                    className="first_name"
                                                    id="first_name"
                                                    name="first_name"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 ">
                                            <div className="input-group mt-3">
                                                <label htmlFor="">Last Name</label>
                                                <input
                                                    type="email"
                                                    placeholder="Last Name"
                                                    className="last_Name"
                                                    id="last_Name"
                                                    name="last_Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 ">
                                            <div className="input-group mt-3">
                                                <label htmlFor="">Last Name</label>
                                                <input
                                                    type="email"
                                                    placeholder="Last Name"
                                                    className="last_Name"
                                                    id="last_Name"
                                                    name="last_Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 ">
                                            <div className="input-group mt-3">
                                                <label htmlFor="">Email Address</label>
                                                <input
                                                    type="email"
                                                    placeholder="Email Address"
                                                    className="email"
                                                    id="email"
                                                    name="email"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 ">
                                            <div className="input-group mt-3">
                                                <label htmlFor="">Password</label>
                                                <input
                                                    type="password"
                                                    placeholder="Password"
                                                    className="password"
                                                    id="password"
                                                    name="password"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                            <span>
                                                <input
                                                    type="checkbox"
                                                    name="check_box"
                                                    id="check_box"
                                                    className="check_box"
                                                />{" "}
                                                I accept the terms and conditions
                                            </span>
                                            <div id="messageBox"></div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <button className="allBtns w-100">Signup</button>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <p className="mt-1">
                                                Already have an account?{" "}
                                                <Button 
                                                    className="SignLoginBtn" 
                                                    onClick={() => { 
                                                        setOpenSignUp(false); // Close the signup modal
                                                        setOpenLogin(true); // Open the login modal
                                                    }}
                                                >
                                                    Login
                                                </Button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    );
}
