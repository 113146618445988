import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Login from "../../assets/images/Login.jpg";
import SignUpModel from "./SignUpPage";

export default function LoginPage({ openLogin, setOpenLogin }) {
  const [openSignUp, setOpenSignUp] = useState(false);

  const handleCloseLogin = () => setOpenLogin(false);
  const handleCloseSignUp = () => setOpenSignUp(false);

  // Open signup modal only after login modal is fully closed
  const handleLoginClosed = () => {
    if (openSignUp) {
      setOpenSignUp(true);
    }
  };

  const handleSignUpClick = () => {
    setOpenLogin(false); // Close login modal first
    setOpenSignUp(true); // Set flag to open signup modal after login closes
  };

  return (
    <>
      <section className="p-0">
        {/* Login Modal */}
        <Modal
          show={openLogin}
          size="lg"
          onHide={handleCloseLogin}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="LoginModel"
          onExited={handleLoginClosed} // Open signup modal when login modal fully closes
        >
          <Modal.Header closeButton className="ModelHeader">
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 text-center">
                  <img src={Login} alt="Login" width="100%" />
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-12">
                      <h2 className="text-center">Login</h2>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-group mt-3">
                          <label htmlFor="">Email Address</label>
                          <input
                            type="email"
                            placeholder="Email Address"
                            className="email"
                            id="email"
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-group mt-3">
                          <label htmlFor="">Password</label>
                          <input
                            type="password"
                            placeholder="Password"
                            className="password"
                            id="password"
                            name="password"
                          />
                        </div>
                      </div>
                      <div className="col-12 mt-3 text-end">
                        <Link className="forgetPasseordLink">Forget Password?</Link>
                      </div>
                      <div className="col-12 mt-4">
                        <button className="allBtns w-100">Login</button>
                      </div>
                      <div className="col-12 mt-3">
                        <p className="mt-1">
                          Don't have an account?{" "}
                          <Button className="SignLoginBtn" onClick={handleSignUpClick}>
                            Signup
                          </Button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* SignUp Modal */}
        <SignUpModel openSignUp={openSignUp} setOpenSignUp={setOpenSignUp} setOpenLogin={setOpenLogin} />
      </section>
    </>
  );
}
