import React, { useState, useEffect } from 'react';
import dummmyImg from '../../assets/images/bannerImg.png'
import PropertyCard from '../../components/PropertyCards';
import Homelogo from '../../assets/images/home.png';
import search from '../../assets/images/search.png'

import mapImg from '../../assets/images/mapImg.png'
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export default function FindProperty() {


    const locationOptions = [
        { value: ' ', label: 'Location' },
        { value: 'Hyderabad', label: 'Hyderabad' },
        { value: 'Vijayawada', label: 'Vijayawada' },
        { value: 'Vishakapatanam', label: 'Vishakapatanam' },
        { value: 'Guntur', label: 'Guntur' },
    ];

    const [selectedLocation, setSelectedLocation] = useState('');
    const handleLocation = (event) => {
        setSelectedLocation(event.target.value);
    };

    const properties = [
        {
            image: dummmyImg,
            price: "$1650/month",
            address: "456A Grand St., Smoky Mountains, TN...",
            bedrooms: "2Bed",
            baths: "2 baths",
            size: "256 Sq.Ft."
        },
        {
            image: dummmyImg,
            price: "$1650/month",
            address: "456A Grand St., Smoky Mountains, TN...",
            bedrooms: "2Bed",
            baths: "2 baths",
            size: "256 Sq.Ft."
        },
        {
            image: dummmyImg,
            price: "$1650/month",
            address: "456A Grand St., Smoky Mountains, TN...",
            bedrooms: "2Bed",
            baths: "2 baths",
            size: "256 Sq.Ft."
        },
        {
            image: dummmyImg,
            price: "$22512/month",
            address: "456A Grand St., Smoky Mountains, TN...",
            bedrooms: "2Bed",
            baths: "2 baths",
            size: "256 Sq.Ft."
        },
        {
            image: dummmyImg,
            price: "$1650/month",
            address: "456A Grand St., Smoky Mountains, TN...",
            bedrooms: "2Bed",
            baths: "2 baths",
            size: "256 Sq.Ft."
        },

        // You can add more properties here
    ];

    const boxes = [
        {
            title: 'Beautiful Landscape',
            image: Homelogo
        },
        {
            title: 'Modern Architecture',
            image: Homelogo
        },
        {
            title: 'Artistic Photography',
            image: Homelogo
        },
        {
            title: 'Nature at its Best',
            image: Homelogo
        },

    ];
    useEffect(() => {
        const boxContainer = document.querySelector('.box-container');
        // Ensure the container exists before adding the event listener
        if (boxContainer) {
            const handleWheel = (event) => {
                event.preventDefault();
                boxContainer.scrollLeft += event.deltaY;
            };
            boxContainer.addEventListener('wheel', handleWheel);
            // Cleanup the event listener when the component unmounts
            return () => {
                boxContainer.removeEventListener('wheel', handleWheel);
            };
        }
    }, []);


    const [range, setRange] = useState([0, 0]);  // Min and max values

    const onSliderChange = (value) => {
        setRange(value);
    };
    const formatCurrency = (value) => {
        if (value >= 10000000) {
            return (value / 10000000).toFixed(2) + ' Cr';
        } else if (value >= 100000) {
            return (value / 100000).toFixed(2) + ' Lakh';
        } else if (value >= 1000) {
            return (value / 1000).toFixed(2) + ' K';
        } else {
            return value;
        }
    };



    const [sqft, setSqft] = useState([0, 0]);  // Min and max values

    const onSqftChange = (value) => {
        setSqft(value);
    };



    return (
        <>
            <section className="p-0 PropertyFilterSec">
                <div className="container-fluid px-2 px-md-0 px-lg-0">
                    <div className="row">
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 order-lg-1 order-md-1 order-sm-1 order-1 mt-2 mt-lg-0 mt-md-0 mt-sm-0 commonSelectDiv   ">
                            <select name="" className="categorySelect commonSelect" id="">
                                <option value="">Select the Category</option>
                                <option value="">Price</option>
                                <option value="">Price</option>
                            </select>
                        </div>
                        <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-md-3 order-sm-3 order-3">
                            <div className="box-container">
                                {boxes.map((box, index) => (
                                    <div key={index} className="box">
                                        <span><img src={box.image} alt={box.title} className="box-image me-2" />{box.title}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 order-lg-3 order-md-2 order-sm-2 order-2 commonSelectDiv">
                            <select className=' locationSelect commonSelect' onChange={handleLocation} name="" id="">
                                <option value="">Select Location</option>
                                <option value="Vijayawada">Vijayawada</option>
                                <option value="Vishakapatanam">Vishakapatanam</option>
                                <option value="Guntur">Guntur</option>
                            </select>
                        </div>
                    </div>
                </div>
            </section>

            <section className='allPropertiesSec p-0'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-5 col-12 mt-5">
                            <div className="card fiterCard">
                                <div className="container">
                                    <div className="row py-3">
                                        <div className="col-8">
                                            <h4>Filters</h4>
                                        </div>
                                        <div className="col-4 text-end">
                                            <button className='clearBtn'>Clear Filter</button>
                                        </div>

                                        <div className="col-12">
                                            <hr className='mt-3 mx-3' />
                                        </div>

                                        <div className="col-12 mt-3">
                                            <Accordion className='filterAccordion' flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <h6>Property Type</h6>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="">
                                                            <input type="checkbox" className='allCheckboxs me-2' id="vehicle1" name="vehicle1" value="Bike" />
                                                            <label for="vehicle1" className=''> Resdencial</label>
                                                        </div>
                                                        <div className="">
                                                            <input type="checkbox" className='allCheckboxs me-2' id="vehicle2" name="vehicle2" value="Bike" />
                                                            <label for="vehicle2" className=''> Comercial</label>
                                                        </div>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>


                                        <div className="col-12">
                                            <hr className='mt-3 mx-3' />
                                        </div>

                                        <div className="col-12 mt-3">
                                            <Accordion className='filterAccordion' flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <h6>Property Type</h6>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="">
                                                            <input type="checkbox" className='allCheckboxs me-2' id="vehicle1" name="vehicle1" value="Bike" />
                                                            <label for="vehicle1" className=''> Resdencial</label>
                                                        </div>
                                                        <div className="">
                                                            <input type="checkbox" className='allCheckboxs me-2' id="vehicle2" name="vehicle2" value="Bike" />
                                                            <label for="vehicle2" className=''> Comercial</label>
                                                        </div>
                                                        <div className="">
                                                            <input type="checkbox" className='allCheckboxs me-2' id="vehicle3" name="vehicle3" value="Bike" />
                                                            <label for="vehicle3" className=''> Open Land</label>
                                                        </div>
                                                        <div className="">
                                                            <input type="checkbox" className='allCheckboxs me-2' id="vehicle4" name="vehicle4" value="Bike" />
                                                            <label for="vehicle4" className=''> For Development</label>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>

                                        <div className="col-12">
                                            <hr className='mt-3 mx-3' />
                                        </div>


                                        <div className="col-12 mt-3">
                                            <Accordion className='filterAccordion' flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <h6>Price range: ₹ {formatCurrency(range[0])} to ₹ {formatCurrency(range[1])}</h6>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="slider-container">
                                                            <Slider
                                                                range
                                                                min={0}
                                                                max={100000000}
                                                                value={range}
                                                                onChange={onSliderChange}
                                                            />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>


                                        <div className="col-12">
                                            <hr className='mt-3 mx-3' />
                                        </div>



                                        <div className="col-12 mt-3">
                                            <Accordion className='filterAccordion' flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <h6>Built Area: {sqft[0]} to {sqft[1]}  sq.ft</h6>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="slider-container">
                                                            <Slider
                                                                range
                                                                min={0}
                                                                max={100000}
                                                                value={sqft}
                                                                onChange={onSqftChange}
                                                            />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>




                                        <div className="col-12 mt-3">
                                            <button className='allBtns w-100'>Apply Filter</button>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-7 col-12 mt-5">
                            <div className="row">
                                <div className="col-12">
                                    {/* <div class="custom-floating-input">
                                        <input type="text" id="floatingInput" placeholder=" " class="form-control" autoComplete='off' />
                                        <label for="floatingInput">Search your property here ...... </label>
                                    </div> */}
                                    <input type="search" placeholder='Search your property here...... ' />
                                </div>
                                <div className="col-12 mt-3">

                                    {selectedLocation === "" ? (
                                        <p><small>12,500 properties are available</small></p>
                                    ) : (
                                        <>
                                            <h6>{selectedLocation}</h6>
                                            <p><small>12,500 properties are available</small></p>
                                        </>
                                    )}


                                </div>

                            </div>
                            <div className="row">
                                {properties.map((property, index) => (
                                    <div key={index} className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12" >
                                        <PropertyCard property={property} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
